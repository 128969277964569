import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0,1,2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'HCAT',
    lpAddresses: {
      97: '0xb88af3f097e12e6509bc47ff3654ba7cc0716ac0',
      56: '0xb88af3f097e12e6509bc47ff3654ba7cc0716ac0',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
 
  {
    pid: 1,
    lpSymbol: 'HCAT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x3fe0e571883d373efda4eccb286972d76ad00424',
    },
    token: tokens.hcat,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x9cd023200fccbeb5c744bfe09ad50e482722e41b',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 3,
    lpSymbol: 'HOW-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xdf6eaad271062f126ddf30b9d191c46921311ee9',
    },
    token: tokens.how,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 4,
    lpSymbol: 'USDT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xc3f5433ff338a1c2e03c2fa6982f07618ed15e40',
    },
    token: tokens.usdt,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 5,
    lpSymbol: 'BTCB-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x8196a755486c77b40c78dbcdf7aff26b57c8900a',
    },
    token: tokens.btcb,
    quoteToken: tokens.usdt,
  },
  
  
  {
    pid: 6,
    lpSymbol: 'ETH-USDT LP',
    lpAddresses: {
      97: '',
      56: '0xcc4101dbad0c77d7e86d080cffe248d67ba69ab8',
    },
    token: tokens.eth,
    quoteToken: tokens.usdt,
  },
  
  {
    pid: 7,
    lpSymbol: 'SOL-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x2e1e56dfa608d9ed6303d31ed2e4c92f1bd6089f',
    },
    token: tokens.sol,
    quoteToken: tokens.usdt,
  },
  
  
  
  
  
  
   
	  
	
// finished last  2  
    

]

export default farms
